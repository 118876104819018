

.userContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 100px auto;
}

.userShow {
  flex: 1;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin:auto  20px ;
  
}

.userUpdate {
  flex: 2;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  margin:auto  20px ;

  width: 250px;
}

.userShowTop {
  display: flex;
  align-items: center;
}



.userShowTopTitle {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.userShowUsername {
  font-weight: 600;
}

.userShowUserTitle {
  font-weight: 300;
}

.userShowBottom{
    margin-top: 20px;
}

.userShowTitle {
  font-size: 14px;
  font-weight: 600;
  color: rgb(175, 170, 170);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon{
    font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}



.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput{
    border: none;
    /* width: 250px; */
    height: 30px;
    border-bottom: 1px solid gray;
}

.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    margin-top: 100px;
   
}



.userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 10px 30px;
    cursor: pointer;
    background-color: teal;
    color: white;
    font-weight: 600;
}
.userUpdateButton:hover{
  background-color: rgb(0, 71, 71);
}

.userShowImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}